function initActive() {
  const clickButton = document.querySelector('[data-click-active]');
  const modalClosers = document.querySelectorAll('[data-closer]');
  if (clickButton) {
    clickButton.addEventListener('click', (e) => {
      const buttonAdd = document.querySelector('[data-text]');
      if (!buttonAdd.classList.contains('_active')) {
        buttonAdd.classList.add('_active');
      } else {
        buttonAdd.classList.remove('_active');
      }
    });
  }
  modalClosers.forEach((modalCloser) => {
    modalCloser.addEventListener('click', () => {
      const parentElem = modalCloser.parentElement;
      if (parentElem.classList.contains('services__opened-wrapper_opened')) {
        parentElem.classList.remove('services__opened-wrapper_opened');
      }
    });
  });
}

document.addEventListener('DOMContentLoaded', initActive);