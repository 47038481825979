import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/src/tiny-slider.scss';

function bindArrows(left, right, slider) {
  const leftArrow = document.querySelector(left);
  const rightArrow = document.querySelector(right);

  if (leftArrow) {
    leftArrow.addEventListener('click', () => {
      slider.goTo('prev');
    });
  }

  if (rightArrow) {
    rightArrow.addEventListener('click', () => {
      slider.goTo('next');
    });
  }
}

if (document.querySelector('[data-offer-slider]')) {
  const slider = tns({
    container: '[data-offer-slider]',
    slideBy: 1,
    nav: true,
    navPosition: 'bottom',
    speed: 700,
    mode: 'gallery',
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    controls: false,
    loop: true,
    swipeAngle: false,
    rewind: false,
  });

  bindArrows('[data-offer-slider-left]', '[data-offer-slider-right]', slider);
}